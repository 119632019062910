import {
  EnvironmentConfiguration,
  environmentSTE,
} from 'polly-commons';

export const environment: EnvironmentConfiguration = {
  ...environmentSTE,
  staticAssets: 'static/manage/',
};

export const dashboardListBaseUrl: string = 'https://test-dashboards.elucidata.io';
